<template>
  <div class="global-search-games">
    <template v-if="isSearching">
      <modal-content-global-search-providers-section
        v-if="hasProviders"
        :items="providerItems"
        :loading="loading"
        :isMobile="isMobile"
      />

      <modal-content-global-search-games-section
        v-if="hasGames"
        :items="gameItems"
        :meta="pageMeta"
        :loading="loading"
        @loadMoreItems="loadMoreItems"
      />
    </template>

    <atomic-empty v-if="isNotFound" :title="emptyTitle" :subTitle="emptyDescription" variant="not-found" />

    <modal-content-global-search-recent-searches-section
      v-if="isLoggedIn"
      v-show="showRecentSearchesSection"
      :params="params"
      @onSelectItem="onSelectItem"
    />

    <modal-content-global-search-games-like-section v-show="showLikeSection" />
  </div>
</template>

<script setup lang="ts">
  import { SortByFields, SortOrders } from '@skeleton/consts/sort';

  import type {
    IGame,
    IGameProvider,
    IGameSearchRequest,
    IGamesResponse,
    IPaginationMeta,
    ISearchRecentRequest,
  } from '@skeleton/core/types';

  const props = defineProps({
    searchValue: {
      type: String,
      default: '',
    },
  });

  const emits = defineEmits<{
    (event: 'updateSearchValue', value: string): void;
  }>();

  const { getSearchGames } = useCoreGamesApi();

  const gamesContentStore = useGamesContentStore();
  const { content: gamesContent } = storeToRefs(gamesContentStore);
  const { updateQueries } = useGlobalSearchStore();
  const { getContent } = useProjectMethods();
  const { isLoggedIn } = storeToRefs(useProfileStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const params = { type: 'game' } as ISearchRecentRequest;

  const loading = ref(false);
  const loadPage = ref(1);
  const pageMeta = ref<IPaginationMeta | null>(null);
  const gameItems = ref<IGame[]>([]);
  const providerItems = ref<IGameProvider[]>([]);

  const itemsPerPage = computed(() => (isMobile.value ? 27 : 40));

  const defaultRequestParams = computed<IGameSearchRequest>(() => ({
    page: loadPage.value,
    perPage: itemsPerPage.value,
    query: props.searchValue || undefined,
    sortBy: SortByFields.NAME,
    sortOrder: SortOrders.ASC.toUpperCase(),
  }));

  const hasProviders = computed(() => providerItems.value.length > 0 || loading.value);
  const hasGames = computed(() => gameItems.value.length > 0 || loading.value);

  const hasSearchQuery = computed(() => !!props.searchValue && props.searchValue.length > 1);
  const isNotFound = computed(() => hasSearchQuery.value && !loading.value && !hasGames.value && !hasProviders.value);
  const isSearching = computed(() => hasSearchQuery.value && !isNotFound.value);

  const showRecentSearchesSection = computed(() => !hasSearchQuery.value);
  const showLikeSection = computed(() => !hasSearchQuery.value || isNotFound.value);

  const emptyTitle = computed(() =>
    getContent(gamesContent?.value?.currentLocaleData, gamesContent?.value?.defaultLocaleData, 'empty.title')
  );

  const emptyDescription = computed(() =>
    getContent(gamesContent?.value?.currentLocaleData, gamesContent?.value?.defaultLocaleData, 'empty.description')
  );

  const onSelectItem = (item: string): void => {
    emits('updateSearchValue', item);
  };

  const changePage = (newPage: number = 1): void => {
    loadPage.value = newPage;
  };

  const loadMoreItems = async (): Promise<void> => {
    const newPage = (pageMeta.value?.page || 0) + 1;
    await changePage(newPage);
    await loadGames(true);
  };

  const resetGames = (): IGamesResponse => ({
    data: {
      games: [],
      providers: [],
    },
    meta: {
      perPage: itemsPerPage.value,
      page: 1,
      totalPages: 0,
      totalRows: 0,
    },
  });

  const setGames = (response: IGamesResponse, append = false): void => {
    const { data, meta } = response;
    const { games, providers = [] } = data;

    gameItems.value = append ? [...gameItems.value, ...games] : games;
    providerItems.value = providers;
    pageMeta.value = meta;
  };

  const loadGames = async (more = false): Promise<void> => {
    if (!props.searchValue) {
      setGames(resetGames());
      return;
    }

    try {
      const response = await getSearchGames(defaultRequestParams.value);
      setGames(response, more);

      if (isLoggedIn.value && (response.data.games.length || response.data.providers.length)) {
        updateQueries(props.searchValue);
      }
    } catch (error) {
      console.error(error);
    }

    loading.value = false;
  };

  watch(
    () => props.searchValue,
    () => {
      if (hasSearchQuery.value) {
        loading.value = true;
        changePage(1);
        loadGames(false);
      }
    },
    { immediate: true }
  );
</script>

<style src="~/assets/styles/components/modal-content/global-search-games.scss" lang="scss" />
